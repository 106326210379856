@tailwind base;
@tailwind components;
@tailwind utilities;

.text-custom-turquoise {
    color: #00bfa6;
}

.bg-custom-gray:hover {
    background-color: #afc1d0;
}

.bg-custom-gray {
    background-color: #54718a;
}

.bg-custom-turquoise {
    background-color: #00bfa6;
}

.text-custom-turquoise {
    color: #00bfa6;
}

.bg-brand-gray {
    background-color: #f3f4f6;
    /* Adjust as needed */
}

@media (max-width: 768px) and (orientation: landscape) {
    
    .logo {
            display: none;
        }
    
    .landscape-adjustment h1 {
        font-size: 6vw;
        /* Adjust the main heading size */
        margin-top: -1rem;
        /* Adjust margin to move it higher */
    }

    .landscape-adjustment .subheading {
        font-size: 3.5vw;
        /* Adjust subheading font size */
        padding-left: 2vw;
        /* Add padding for better spacing */
    }

    .landscape-adjustment .content-wrapper {
        padding: 2vw;
        font-size: 2.8vw;
        /* Add padding for content */
    }

    .landscape-adjustment .email-signup-form {
        flex-direction: column;
        /* Stack elements vertically */
        gap: 8px;
        /* Space between elements */
    }
}

@media (max-width: 741px) and (orientation: landscape) {

    .logo {
            display: none;
        }

    .landscape-adjustment {
            padding-top: 35vw;
            /* Adds space between top of screen and content */
        }

    /* Adjust the main heading */
    .landscape-adjustment h1 {
        font-size: 8vw;
        margin-top: 0;
        /* Reset margin for compact spacing */
        line-height: 1.1;
        /* Adjust line height for readability */
        padding-right: 1vw;
        /* Slight padding to prevent text overflow */
    }

    /* Subheading styling */
    .landscape-adjustment .subheading {
        font-size: 2.8vw;
        /* Smaller font size for better fit */
        padding-left: 1vw;
        margin-top: -0.5rem;
    }

    /* Content wrapper (Manifesto text area) */
    .landscape-adjustment .content-wrapper {
        padding: 0.5vw;
        font-size: 2.5vw;
        /* Reduce text size for readability */
        line-height: 1.3;
        /* Adjust line spacing for legibility */
    }

    /* Signup form adjustments */
    .landscape-adjustment .email-signup-form {
        flex-direction: column;
        gap: 4px;
        /* Compact spacing between input and button */
    }

    /* Button adjustments */
    .landscape-adjustment .button {
        font-size: 2.8vw;
        padding: 0.4rem 0.8rem;
    }

    /* Input adjustments */
    .landscape-adjustment input {
        font-size: 2.8vw;
        padding: 0.4rem 0.8rem;
    }

    /* Ensure the "Read More" button fits within layout */
    .landscape-adjustment .read-more {
        font-size: 2vw;
        padding: 0.3rem 0.6rem;
    }
}